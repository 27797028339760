<template>
  <v-sheet>
    <v-chip-group active-class="teal--text text--darken-2" mandatory>
      <v-chip v-for="(group, i) in groups" :key="i" class="ml-2">
        {{ group.name }} | {{ group.startDate }} - {{ group.endDate }}
      </v-chip>
    </v-chip-group>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title @click="addWidth()">
              Kitchen {{ value.x }}
            </v-card-title>
          </v-card>
        </v-col>
        <v-carousel
          cycle
          height="400"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(slide, i) in groups" :key="i">
            <v-sheet color="red" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-h2">{{ slide.name }} Slide</div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import * as Utils from "../classes/classes";

export default {
  name: "KitchenHome",
  data: function () {
    return {
      value: new Utils.Rectangle(2, 3),
      groups: [
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
      ],
    };
  },
  methods: {
    addWidth() {
      this.value.addWidthFunction(1);
    },
  },
};
</script>
